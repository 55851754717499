import React from 'react'
import Layout from '../components/Layout'

import { Container } from 'react-bootstrap';


import EnvelopeSimple from '../images/EnvelopeSimple.svg';
import Phone from '../images/Phone.svg';
import Facebook from '../images/facebook-app-logo.png';
import Instagram from '../images/instagram.png';

function contact() {
  return (
    <Layout>
      <Container className="contact-page">

        <div className="contact-content">
          <h1>Contactinformatie</h1>
          <span><img src={EnvelopeSimple} alt="map"/><a href="mailto:ensemble@duo-juna.nl">ensemble@duo-juna.nl</a></span>
          <span><img src={Facebook} alt="map"/><a href="https://www.facebook.com/Duojuna" target="_blank">facebook.com/duojuna</a></span>
          <span><img src={Instagram} alt="map"/><a href="https://www.instagram.com/duojuna/">instagram.com/duojuna</a></span>

        </div>
        
      </Container >
    </Layout>
  )
}

export default contact
